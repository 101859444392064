@import 'style/shared';

footer {
  position: absolute;
  background-color: $footer-background;
  padding-top: 71px;
  width: 100%;
  padding-bottom: 19px;
  z-index: 20;
  clip-path: polygon(100% 3%, 100% 100%, 0% 100%, 0% 3%, 50% 2%, 50% 2%);

  &::before {
    display: block;
    content: ' ';
    position: absolute;
    top: 24px;
    left: 50%;
    transform: translate(-50%, 50%);

    width: $mobile-first;
    height: 15px;
    background-image: url('../../assets/images/footer_check_mob.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    @media screen and (min-width: $desktop-up) {
      top: 0;

      background-image: url('../../assets/images/footer_check.png');
      width: 1785px;
      height: 125px;
    }
  }

  @media screen and (min-width: $desktop-up) {
    clip-path: polygon(100% 8%, 100% 100%, 0% 100%, 0% 8%, 50% 0, 50% 0%);
    padding-top: 220px;
  }
}



.tittle {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;

  font-size: 16px;
  line-height: 1.1;

  text-align: center;
  text-transform: uppercase;
  color: $third-tittle-color;
  margin: 0 auto 53px auto;


  &::before,
  &::after {
    display: block;
    content: ' ';
    background-image: url('../../assets/images/petal.svg');
    width: 16px;
    height: 3px;
    background-size: cover;
    background-position: center;
    margin-top: 5px;
  }

  &::before {
    transform: rotate(180deg);
  }

  span {
    margin: 0 10px;
  }


  @media screen and (min-width: $desktop-up) {
    font-size: 50px;
    line-height: 1.1;

    span {
      margin: 0 40px;
    }

    &::before,
    &::after {
      width: 41px;
      height: 6px;
      margin-top: 12px;
    }
  }
}

.wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;

  margin-top: 34px;

  @media screen and (min-width: $desktop-up) {
    flex-direction: row;
  }
}

.logo {
  width: 127px;
  height: 139px;

  @media screen and (min-width: $desktop-up) {
    width: 200px;
    height: 219px;
    margin-right: 0;
    margin-left: 128px;
  }

}

.listRule {
  margin-top: 46px;

  li+li {
    margin-top: 21px;
  }


  @media screen and (min-width: $desktop-up) {
    display: grid;
    grid-template-columns: repeat(3, 302px);
    grid-template-rows: repeat(2, 1fr);
    grid-auto-flow: column;
    justify-content: space-evenly;

    width: 100%;
    height: 200px;
    margin-left: 104px;


    li+li {
      margin-top: 0;
    }

    li:nth-child(even) {
      margin-top: 52px;
    }
  }

}

.city {
  position: absolute;
  width: 100%;
  bottom: 80px;
  left: 0;
}

.confident,
.policy {
  display: block;
  font-family: 'Myriad-Pro';
  font-size: 12px;
  line-height: 1.1;
  text-align: center;

  color: $fourth-text-color;


  @media screen and (min-width: $desktop-up) {
    font-size: 26px;
  }

}

.confident{
  margin-top: 43%;
    @media screen and (min-width: $desktop-up) {
    margin-top: 14%;
  }
}

.policy{
  font-weight: 700;
  margin-top: 5px;
  text-decoration: underline;

  @media screen and (min-width: $desktop-up) {
    font-size: 24px;
  }
}
.btn{
  cursor: pointer;
  background-color: inherit;
  border: none;

  width: 150px;
  height: 80px;
}

.icon{
  width: 60px;
  height: 60px;
}

@import 'style/shared';

.Modal {
  position: absolute;
  top: 0;
  width: calc(100% - 12px);
  height: calc(100% - 5px);
  padding: 0 10px;

  @media screen and (min-width: $desktop-up) {
    position: static;
    //width: fit-content;
    width: fit-content;
    height: fit-content;
    max-width: calc(100% - 48px);
    max-height: calc(100% - 24px);
  }
  
  
}
.Button {
  position: relative;
  top:45px;
  display: block;
  margin-left: auto;

  background-color: inherit;
  border: inherit;

  @media screen and (min-width: $desktop-up) {
    top: 60px;
    right: -5px;
    padding: 5px 28px;
  }

}

.Warper{
  display: flex;
  justify-content: space-evenly;
  background-color: $second-background;
  padding: 60px 8px 16px 8px;

  @media screen and (min-width: $desktop-up) {
    padding: 60px 74px;
  }
}
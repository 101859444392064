@import 'style/shared';

.wrap {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  picture + picture {
    margin-left: 20px;
  }

  @media screen and (min-width: $desktop-up) {
    flex-direction: row;
    &::after {
      position: absolute;
      display: block;
      left: 300px;
      content: ' ';
      width: 5px;
      height: 211px;
      border-radius: 50%;
      background-color: $third-background;
    }

    position: absolute;
    //top: -135px;
    top: -295px;
    left: 95px;
    z-index: 1200;

    picture + picture {
      margin-left: 90px;
    }
  }
}


.sponsorsReserveCover {
  height: 52px;
}

.flex{
  display: flex;
  flex-direction: column;
}

.flexWrap{
  display: flex;
  flex-direction: row;
}

.LvivskeBearLogo {
  width: auto;
  height: 44px;

  @media screen and (min-width: $desktop-up) {
    width: auto;
    height: 100%;
  }
}

.LvivskeBearLogoSlogan {
  color: #ffffff;
  font-size: 6px;
  display: block;
  margin-top: 5px;
  margin-bottom: 14px;
  width: 120px;
  line-height: 120%;
  text-align: center;


  @media screen and (min-width: $desktop-up) {
    margin-top: 15px;
    width: 460px;
    font-size: 21px;
  }
}

.UkrainefederationLogo {
  width: auto;
  height: 43px;

  @media screen and (min-width: $desktop-up) {
    width: auto;
    height: 202px;
  }
}

@import 'style/shared';

.card{
  position: relative;
  padding: 8px 0 0 0;
}

.chart{
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  border: 1px solid #C59B5A;
  border-radius: 50%;


  width: 27px;
  height: 27px;
  @media screen and (min-width: $desktop-up) { 
    width: 66px;
    height: 66px;
  }

  div{
    width: 23px;
    height: 23px;

    @media screen and (min-width: $desktop-up) { 
      width: 62px;
      height: 62px;
    }
  }

  p{
    position: absolute;
    top: 56%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 11px;
    line-height: 1.1;

    color: #F6DAAB;

    @media screen and (min-width: $desktop-up) { 
      font-size: 24px;
      line-height: 1.1;
    }

  }


}

.cover{
  width: 72px;
  height: auto;
  margin:0 auto;
  
  @media screen and (min-width: $desktop-up) { 
    width: 170px;
  }
}

.counter{
  position: absolute;
  top: 0;
  right: 0;

  display: flex;
  flex-direction: column;
  align-items: center;
  img{
    width: 19px;
    height: 11px;
    @media screen and (min-width: $desktop-up) { 
      width: 47px;
      height: 29px;
    }
  }

  span{
    font-size: 18px;
    line-height: 1.1;

    margin-top: 2px;
    color: $second-text-color;

    @media screen and (min-width: $desktop-up) { 
      font-size: 44px;
      line-height: 1.1;
      margin-top: 4px;
    }

  }
}

.name{
  position: relative;
  top: -5px;
  font-size: 9px;
  line-height: 1.1;
  text-align: center;

  color: $primary-text-color;

  background-image: url('../../assets/images/bord.svg');
  background-size: cover;
  background-position: center;
  padding: 14px 4px 9px 4px;

  z-index: 5;

  @media screen and (min-width: $desktop-up) {
    top: -10px;

    font-size: 20px;
    line-height: 1.1;

    padding: 30px 20px 24px 20px;
  }
}

.btnWrap{
  min-height: 35px;
  margin-top: 10px;
  @media screen and (min-width: $desktop-up) {
    margin-top: 26px;
    min-height: 84px;
  }
}

.AwardsSubWrap {
  position: relative;
  display: flex;
}
.AwardsCount {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  font-weight: 700;
  top: -2px;
  font-size: 9px;

  @media screen and (min-width: $desktop-up) {
    top: initial;
    font-size: 15px;
  }
}
.AwardsIcon {
  width: 15px;
  height: auto;
  object-fit: contain;

  @media screen and (min-width: $desktop-up) {
    width: 25px;
    height: 25px;
    object-fit: contain;
  }
}
.AwardsNameWrap {
  display: flex;
  align-items: flex-end;
  gap: 5px;
}
@import 'style/shared';

.wrap {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  margin: -10px 0;

  @media screen and (min-width: $desktop-up) {
    margin: -20px -15px;
    justify-content: flex-start;
  }
}
.wrapNews {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;

  @media screen and (min-width: $desktop-up) {
    flex-direction: row;
    align-items: flex-start;
  }
}
.newsItemBox {
  width: 217px;
  height: fit-content;

  @media screen and (min-width: $desktop-up) {
    width: 545px;
  }

}

.video {
  width: 217px;
  height: auto;

  margin: 10px 0;

  @media screen and (min-width: $desktop-up) {
    position: relative;
    width: 401px;
    height: auto;

    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.8) 0%,
      rgba(0, 0, 0, 0) 60.62%
    );

    margin: 20px 15px;

  }
}
.videoFrame{
  width: 700px;
  height: 400px;
}

.thumb{
  @media screen and (min-width: $desktop-up) {
    position: relative;
    cursor: pointer;

    &::before {
      display: block;
      position: absolute;
      top: 50%;
      right: 50%;
      transform: translate(50%, -50%);
      width: 85px;
      height: 85px;
      content: ' ';
      background-image: url('../../assets/images/you_tube_btn.png');
      background-size: cover;
      background-position: center;
      z-index: 150;
    }
  }
}


.wrapModal{
  margin-top: 40px;
}
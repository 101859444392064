.Content {
  display: flex;
  flex-direction: column;
}
.Content .MainTitle {
  font-weight: 400;
  font-size: 44px;
  line-height: 47px;
  color: white;
  display: block;
  text-align: center;
}
.gridWrap {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 15px;
  grid-row-gap: 30px;
}
.FlexWrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}
.CustomEl {
  display: flex;
  align-items: center;
}
.CustomElTitle {
  font-weight: 400;
  font-size: 32px;
  line-height: 34px;
  color: white;
  margin: 0 9px;
}
.Description {
  font-weight: 400;
  font-size: 20px;
  line-height: 21px;
  color: white;
  margin-top: 18px;
  text-align: center;
}
.CustomEl:before, .CustomEl:after {
  display: block;
  content: ' ';
  background-image: url('../../assets/images/petal.svg');
  width: 20px;
  height: 3px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.CustomEl:before {
  transform: rotate(180deg);
}
.CustomWrapper {
  min-height: 363px;
  max-width: 600px;
  width: 100%;
  background-image: url("../../assets/images/statistic-modal-bg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  /*background-color: #0F442D;*/
  /*clip-path: polygon(50% 4%, 100% 0, 100% 100%, 50% 95%, 0 100%, 0 0);*/
  position: relative;
  padding: 82px 20px 40px;
  /*filter: blur(20px);*/
}
.CustomWrapper:before {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  right: 0;
  top: -15px;
  background-image: url("../../assets/images/big-arrow-top.svg");
  background-repeat: no-repeat;
  background-size: contain;
}
.CustomWrapper:after {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  right: 0;
  /*bottom: -96%;*/
  top: 20px;
  background-position: bottom;
  background-image: url("../../assets/images/big-arrow-bottom.svg");
  background-repeat: no-repeat;
  background-size: contain;
  z-index: -1;
}
.CustomWrapperContent {
  background-color: transparent !important;
}
.HintWrap {
  position: absolute;
  right: 12px;
  top: -18px;
}
.Hint {
  position: absolute;
  min-width: 200px;
  min-height: 52px;
  background: #C59B5A;
  border-radius: 12px;
  bottom: 145%;
  /*left: 68%;*/
  left: -100%;
  padding: 8px 16px;
  font-family: 'Myriad-Pro', sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: white;
  line-height: 18px;
}
.Hint:before {
  content: '';
  width: 0;
  height: 0;
  border-top: 7px solid transparent;
  border-bottom: 8px solid transparent;
  border-left: 8px solid #C59B5A;
  transform: rotate(90deg);
  position: absolute;
  /*left: 16px;*/
  left: 22px;
  bottom: -11px;
}
.Button {
  position: absolute;
  top: 20px;
  right: 20px;
  background-color: transparent;
  border: none;
  padding: 0;
  margin: 0;
}
.InfoBtn {
  cursor: pointer;
  background-color: transparent;
  border: none;
  padding: 0;
  margin: 0;
}

.replacementPlayersWrap {
  position: absolute;
  left: 15px;
  top: 35%;
  transform: translateY(-50%);
}
.DefaultReplacementNum {
  color: white;
  font-weight: 400;
  font-size: 14px;
  position: absolute;
  min-width: 15px;
  text-align: center;
}
.TopNun {
  top: 8px;
  left: 6px;
}
.BotNum {
  bottom: 4px;
  right: 6px;
}

.ContentTopWrap {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 60px;
  position: relative;
}

.ReplacementIcon {
  width: 50px;
  height: 50px;
  vertical-align: middle;
}

@media screen and (max-width: 868px) {
  .Hint {
    min-width: 135px;
  }
}

@media screen and (max-width: 768px) {
  .CustomElTitle {
    margin: 0 5px;
  }
  .Content .ContentTopWrap {
    margin-bottom: 50px;
  }
  .Description {
    font-size: 16px;
    margin-top: 13px;
  }
  .Hint {
    padding: 8px;
    min-width: 140px;
    /*left: 0;*/
    left: auto;
    right: -100%;
  }
  .Hint:before {
    left: auto;
    /*right: 28px;*/
    right: 23px;
  }
}

@media screen and (max-width: 600px) {
  .gridWrap {
    grid-template-columns: repeat(3, 1fr);
  }
  /*.HintWrap {*/
  /*  right: 22px;*/
  /*}*/
  .Hint {
    min-width: 170px;
    text-align: center;
  }
}
@media screen and (max-width: 500px) {
  .Content .MainTitle {
    font-size: 33px;
  }
  .gridWrap {
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 5px;
  }
  .replacementPlayersWrap {
    transform: initial;
    left: 0;
    top: -100%;
  }
  .CustomWrapper {
    padding: 82px 10px 40px 10px;
  }
  /*.Hint {*/
  /*  min-width: 228px;*/
  /*}*/
}

/*@media screen and (max-width: 390px) {*/
/*  .Hint {*/
/*    min-width: 175px;*/
/*  }*/
/*}*/

@media screen and (max-width: 375px) {
  .Hint {
    max-width: 170px;
  }
  .replacementPlayersWrap {
    transform: initial;
    left: 0;
    top: -110%;
  }
}

@media screen and (max-width: 320px) {
  /*.replacementPlayersWrap {*/
  /*  transform: initial;*/
  /*  left: 0;*/
  /*  top: -60%;*/
  /*}*/
  .Content .MainTitle {
    font-size: 30px;
  }
  .Hint {
    max-width: 150px;
    min-width: 150px;
  }
}
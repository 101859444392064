@import 'style/shared';

.main {
  width: 100%;
  background-color: $second-background;

  section {
    padding-top: 40px;
    padding-bottom: 0;

    @media screen and (min-width: $desktop-up) {
      padding-top: 100px;
      padding-bottom: 0;
    }
  }

  section:last-child {
    padding-bottom: 40px;

    @media screen and (min-width: $desktop-up) {
      padding-bottom: 100px;
    }
  }
}

.cover {

  width: 217px;
  height: auto;
  margin: 0 auto;

  @media screen and (min-width: $desktop-up) {
    width: 1200px;
  }

}

.mainTitle {
  font-size: 24px;
  line-height: 1.3;

  margin-top: 40px;

  color: $fourth-text-color;

  @media screen and (min-width: $desktop-up) {
    font-size: 52px;
    line-height: 1.3;

    margin: 40px auto 0 auto;
    width: 1120px;
  }
}

.tittle {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;

  font-size: 16px;
  line-height: 120%;
  margin: 0 auto 53px auto;

  text-align: center;
  text-transform: uppercase;
  color: $third-tittle-color;

  &::before,
  &::after {
    display: block;
    content: ' ';
    background-image: url('../../assets/images/petal.svg');
    width: 16px;
    height: 3px;
    background-size: cover;
    background-position: center;
    margin-top: 5px;
  }

  &::before {
    transform: rotate(180deg);
  }

  @media screen and (min-width: $desktop-up) {
    font-size: 50px;
    line-height: 1.1;
    margin: 0 auto 81px auto;

    span {
      margin: 0 40px;
    }

    &::before,
    &::after {
      width: 41px;
      height: 6px;
      margin-top: 12px;
    }
  }
}

.newsSection {
  p {
    color: $fourth-text-color;
  }
}

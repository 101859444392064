@font-face {
  font-family: Lvivske-Basic;
  font-display: swap;
  src: url('../../assets/fonst/Lvivske-Basic-Regular.ttf') format('truetype');
}

@font-face {
  font-family: Lvivske-High;
  font-display: swap;
  src: url('../../assets/fonst/Lvivske-High-Regular.ttf') format('truetype');
}

@font-face {
  font-family: Myriad-Pro;
  font-display: swap;
  src: url('../../assets/fonst/Myriad-Pro.ttf') format('truetype');
}

@font-face {
  font-family: Myriad-Pro-Bold;
  font-display: swap;
  src: url('../../assets/fonst/Myriad-Pro-Bold.ttf') format('truetype');
}

@font-face {
  font-family: Myriad-Pro-Bold-Italic;
  font-display: swap;
  src: url('../../assets/fonst/Myriad-Pro-Bold-Italic.ttf') format('truetype');
}

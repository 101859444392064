@import 'style/shared';

.coverNew{
  height: 124px;


  @media screen and (min-width: $desktop-up) {
    height: 310px;
  }
}

.titleNew{
  font-size: 18px;
  line-height: 1.3;

  margin-top: 12px;
  color: $primary-text-color;

  @media screen and (min-width: $desktop-up) {
    font-size: 34px;
    line-height: 1.3;
  }

}
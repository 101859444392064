@import 'style/shared';
// $desktop-up:1200;

.main {
  width: 100%;
  background-color: $second-background;

  section {
    padding-top: 40px;
    padding-bottom: 0;

    @media screen and (min-width: $desktop-up) {
      padding-top: 100px;
      padding-bottom: 0;
    }
  }

  section:last-child {
    padding-bottom: 40px;

    @media screen and (min-width: $desktop-up) {
      padding-bottom: 100px;
    }
  }
}

.mainTitle {
  font-size: 24px;
  line-height: 1.3;
  text-align: center;

  margin-top: 40px;

  color: $fourth-text-color;

  @media screen and (min-width: $desktop-up) {
    font-size: 52px;
    line-height: 1.3;

    margin: 40px auto 0 auto;
    width: 1120px;
  }
}

.profileText,
.warningText {
  font-family: 'Myriad Pro';
  font-weight: 700;
  font-size: 13px;
  line-height: 1.4;
  span {
    margin-right: 3px;
  }
  @media screen and (min-width: $desktop-up) {
    font-size: 24px;
  }
}

.dataProfile {
  padding: 10px 0 10px 10px;
  @media screen and (min-width: $desktop-up) {
    margin-bottom: 20px;
    padding: 10px 40px;
  }
}

.warningText {
  display: block;
  font-size: 10px;
  color: red;
  @media screen and (min-width: $desktop-up) {
    font-size: 22px;
    margin-bottom: 20px;
  }
}
.wrap {
  padding: 10px;
  @media screen and (min-width: $desktop-up) {
    padding: 10px 40px;
  }
  div {
    justify-content: center;
  }
}

.card {
  padding: 13px;
  border: 1px solid $primary-border-color;
  border-radius: 30px;
  @media screen and (min-width: $desktop-up) {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    padding: 40px 200px;
  }
}

.imageThumb {
  display: none;
  @media screen and (min-width: $desktop-up) {
    display: block;
  }
}

@import 'style/shared';

.headerHome {
  position: absolute;
  z-index: 100;
  width: 100%;
  padding: 18px 0;

  @media screen and (min-width: $desktop-up) {
    padding: 110px 0;
    &::after {
      display: block;
      position: absolute;
      top: 0;
      right: 168px;
      width: 261px;
      height: 99px;
      content: ' ';
      background-image: url('../../assets/images/flag_five.png');
      background-size: cover;
      background-position: center;
      z-index: 150;
    }
  }

}

.wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  @media screen and (min-width: $desktop-up) {
    justify-content:  flex-end;
    margin-right: 188px;
  }
}

.headerNews{
  padding: 10px 0;
  background: $footer-background;
  .wrap{
    align-items: center;
  }

  @media screen and (min-width: $desktop-up) {
    .wrap{
      div{
        top: 43px;
      }
    }

    padding: 23px 0;
  }
}

.logoLink{
  margin-right: auto;
}

.logo{
  width: 41px;
  height: 45px;
  @media screen and (min-width: $desktop-up) {
    width: 78px;
    height: 86px;
  }
}

@import 'style/shared';

.customHeaderWrap {
  .flagHeader {
    max-height: 60px;
  }
  @media screen and (max-width: 767px) {
    min-width: 150px;
    .wrapInfo {
      margin: 0 10px;
      align-self: center;
    }
    .flagHeader {
      max-height: 24px;
    }
  }
}

.customElementWrap {
  margin-bottom: 5px;
  .flagFavorites + .flagFavorites {
    order: 1;
    margin: 0 !important;
  }
  .wrapInfo {
    align-self: center;
    margin: 2px 5px 0 5px;
  }
}

.wrap,
.wrapFavorite {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;

  margin-top: 16px;

  .flagFavorites + .flagFavorites {
    margin-left: 10px;
    margin-bottom: 4px;
  }
}

.wrapFavorite {
  @media screen and(min-width: $desktop-up) {
    justify-content: space-between;
  }
}

.wrapInfo {
  @media screen and(min-width: $desktop-up) {
    margin: 0 25px;
  }
}
.wrapInfoFavorite {
  @media screen and(min-width: $desktop-up) {
    margin: 0 7px;
  }
}

.title,
.titleFavorite,
.titleFavoriteCorrectSize {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  font-family: 'Myriad-Pro';
  font-weight: 600;
  font-size: 10px;
  line-height: 1.1;

  text-align: center;
  text-transform: uppercase;

  color: $primary-tittle-color;

  margin: 0 10px;

  span {
    font-size: 3px;
    line-height: 1.1;
    margin: 2px 0;
    position: relative;

    &::after,
    &::before {
      display: block;
      position: absolute;
      top: 50%;
      content: ' ';

      width: 15px;
      height: 1px;

      background-color: $second-background;
    }

    &::after {
      left: 0;
      transform: translate(50%, -50%);
    }

    &::before {
      right: 0;
      transform: translate(-50%, -50%);
    }

    @media screen and (min-width: $desktop-up) {
      font-size: 9px;
      line-height: 1.1;
      &::after,
      &::before {
        width: 23px;
      }
      &::after {
        left: 4px;
      }

      &::before {
        right: 4px;
      }
    }
  }

  @media screen and (min-width: $desktop-up) {
    font-size: 14px;
    line-height: 1.1;
  }
}

.titleFavorite {
  margin: 0 5px;
  @media screen and (min-width: $desktop-up) {
    font-size: 14px;
    line-height: 1.1;
  }
}
.titleFavoriteCorrectSize {
  font-size: 7px;
  line-height: 1.1;
  @media screen and (min-width: $desktop-up) {
    font-size: 9px;
  }
}

.flagFavorites,
.flagHeader {
  overflow: hidden;
  background: $flag-background;
  border: 1px solid #b3c1ca;
  @media screen and (min-width: $desktop-up) {
    border: 2px solid #b3c1ca;
  }
}

.flagHeader {
  width: 24px;
  height: 100%;
  border-radius: 83px;
  @media screen and (min-width: $desktop-up) {
    width: 60px;
    height: 100%;
  }
}

.flagFavorites {
  width: 17px;
  height: 17px;
  border-radius: 83px;
  @media screen and (min-width: $desktop-up) {
    width: 40px;
    height: 40px;
  }
}

.status {
  font-family: 'Myriad-Pro';
  font-weight: 600;
  font-size: 14px;
  line-height: 1.1;

  text-align: center;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  color: $third-text-color;

  @media screen and (min-width: $desktop-up) {
    font-size: 40px;
    line-height: 1.2;
  }
}

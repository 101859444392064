@import 'style/shared';

.table {
  font-size: 10px;
  @media screen and (min-width: $desktop-up) {
    font-size: 16px;
  }
  .subTitle {
    text-align: center;
    color: $third-tittle-color;
    border: 1px solid $primary-border-color;
    font-size: 16px;
    margin-top: 12px;
    padding: 6px 8px 0;
    @media screen and (min-width: $desktop-up) {
      font-size: 24px;
      padding: 8px 28px 0;
    }
  }
  .row {
    display: flex;
    font-family: Myriad-Pro;
    font-style: normal;
    padding: 4px 0 4px 0;
    &:not(:last-of-type) {
      border-bottom: 1px solid $primary-border-color;
    }
    @media screen and (min-width: $desktop-up) {
      display: grid;
      padding: 12px 140px 12px 0;
      grid-template-columns: 140px auto;
    }
  }
  .cell {
    min-height: auto;
    padding: 2px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 85%;
    @media screen and (min-width: $desktop-up) {
      padding: 4px 8px;
      min-height: 54px;
    }
    &:first-of-type {
      border-right: 1px solid $primary-border-color;
      @media screen and (min-width: $desktop-up) {
        border-right: none;
      }
    }
    .country {
      display: none;
      @media screen and (min-width: $desktop-up) {
        display: block;
      }
    }
    .countryMob {
      display: flex;
      @media screen and (min-width: $desktop-up) {
        display: none;
      }
      .country {
        flex: 0 0 50%;
        display: block;
        margin-top: 2px;
        padding-right: 4px;
        @media screen and (min-width: $desktop-up) {
          display: none;
        }
        &:last-of-type{
          padding-right: 0;
          padding-left: 4px;
        }
      }
    }
    &.cellTime {
      border-right: 1px solid $primary-border-color;
      flex-wrap: wrap;
      flex-direction: column;
      flex: 0 0 15%;
      @media screen and (min-width: $desktop-up) {
        flex-direction: row;
      }
    }
    time + time{
      color: $third-tittle-color;
      @media screen and (min-width: $desktop-up) {
        margin-left: 4px;
      }
    }
    .tour{
      background-color: $d-orange-background;
      color: $primary-tittle-color;
      border-radius: 4px;
      white-space: nowrap;
      @media screen and (min-width: $desktop-up) {
        min-width: 64px;
        height: 45px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 2px;
      }
    }
    &.cellResult {
      display: block;
      @media screen and (min-width: $desktop-up) {
        display: flex;
        align-items: center;
      }
      .resultWrap {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        max-width: 100%;
      }
      .resultItem{
        display: flex;
        align-items: center;
        flex: 0 0 50%;
        max-width: 50%;
        &.left{
          justify-content: flex-end;
          //flex: 0 0 50%;
          .countryIcon {
            margin: 0 auto 0 31px;
            @media screen and (min-width: $desktop-up) {
              margin: 0 12px;
            }
          }
        }
        &.right{
          .countryIcon {
            margin: 0 32px 0 auto;
            @media screen and (min-width: $desktop-up) {
              margin: 0 12px;
            }
          }
        }
      }
      .country {
        white-space: normal;
        word-break: break-word;
        font-size: 8px;
        @media screen and (min-width: $desktop-up) {
          font-size: 16px;
        }
      }
      .countryIcon {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        @media screen and (min-width: $desktop-up) {
          margin: 0 12px;
          width: 32px;
          height: 32px;
        }
      }
      .result {
        margin: 0 4px;
        min-width: 12px;
        @media screen and (min-width: $desktop-up) {
          margin: 0 20px;
          min-width: 20px;
        }
      }
    }
  }
}




@import 'style/shared';
.InputLabel{
  width: 100%;
  //max-width: 278px;
}

.hideLabel{
  @extend %hide;
}

input{
  font-family: 'Myriad-Pro';
  font-size: 17px;
  line-height: 1.1;
  text-align: center;

  padding: 12px 8px 14px 8px;

  width: 100%;

  color: $input-text-color;

  @media screen and (min-width: $desktop-up) {
    padding: 12px 50px 14px 50px;
  }
  &:disabled {
    opacity: .4;
  }
}
.hideBorder {
  border: 1px solid #C59B5A !important;
}
.massage{
  font-family: 'Myriad-Pro';
  
  font-size: 13px;
  line-height: 1;

  color: red;
}
.hide {
  display: none;
}
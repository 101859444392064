
.help{
  position: fixed;

  top:50%;
  right: 10px;

  transform: translate(0, 50%);
  border-radius: 10px;
  padding: 3px;
  background-color: gray;

  z-index: 500;
  overflow: hidden;
  cursor: pointer;
}

.personalCab{
  width: 50px;
  height: auto;
  border-radius: 10px;
  background-color: $footer-background;
}

.adaptive,
.adaptiveOther,
.adaptiveProfile{
  width: 250px;

  @media screen and (min-width:  $desktop-up) {
    width: 1920px;
    height: 1920px;
  }
}

.adaptiveOther{
  @media screen and (min-width:  $desktop-up) {
    height: 100vh;
  }
}

.adaptiveProfile{
  @media screen and (min-width:  $desktop-up) {
    height: 935px;
  }
}
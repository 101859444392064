//background
$primary-background: #093A29;
$second-background: #ffffff;
$third-background:#F0C10A;
$footer-background: #F6DAAB;
$orange-background: #F6DAAB;
$d-orange-background: #C59B5A;
$google-background: #1876F2;
$flag-background: #FFDA2C;

$ok-border-color:#16894B;



//fill
$pagination-fill:#C59B5A;
// chart
$primary-chart: #16894B;

//counter
$primary-counter-color:#FBE000;

//Text Color
$primary-text-color:#ffffff;
$second-text-color:#16894B;
$third-text-color:#FBE000;
$fourth-text-color:#C59B5A;
$fifth-text-color:#F6DAAB;
$input-text-color:#303030;
$auth-text-color:#5F5F5F;

//Tittle Color
$primary-tittle-color:#ffffff;
$second-tittle-color:#FBE000;
$third-tittle-color:#C59B5A;

//border
$primary-border-color:#C59B5A;
$second-border-color:#16894B;
$third-border-color:#CDCDCD;

//accent
$primary-accent-color:#000000;

//overlay
$overlay-background: rgba(0, 0, 0, 0.6);

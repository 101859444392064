.defButton {
  cursor: pointer;
  background-color: transparent;
  text-align: center;
  margin: 0;
  padding: 0;
  border: none;
  width: 100%;
  display: block;
}
.defButton span {
  font-weight: 400;
  font-size: 16px;
  color: #FFFFFF;
  display: inline-block;
  position: relative;
}
.defButton span:after {
  content: '';
  width: 100%;
  height: 1px;
  display: block;
  position: absolute;
  bottom: 0;
  background-color: white;
}
@media screen and (max-width: 768px) {
  .defButton span {
    font-size: 10px;
  }
}
//reset

ul,
ol {
  margin: 0;
  padding: 0;
  list-style: none;
}


h1,
h2,
h3,
h4,
p {
  margin-top: 0;
  margin-bottom: 0;
}

a {
  text-decoration: none;
  display: inline-block;
}

img {
  display: block;
  max-width: 100%;
}


button {
  cursor: pointer;
  text-transform: none;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
}
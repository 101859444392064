@import 'style/shared';
.wrap{
  display: flex;
  flex-direction: column;

  width: 110px;
  margin-right: auto;

  @media screen and (min-width: $desktop-up) {
    margin-right: 40px;
    width: 256px;
  }
}

.title{
  font-family: 'Myriad-Pro';
  font-weight: 600;
  font-size: 6px;
  line-height: 1.1;
  text-transform: uppercase;

  color: $primary-text-color;

  @media screen and (min-width: $desktop-up) {
    font-size: 14px;
    line-height: 1.1;
  }


}
.massage,
.loading{
  display: block;
  font-style: normal;
  font-family: 'Myriad-Pro', sans-serif ;
  font-weight: 600;
  font-size: 11px;
  line-height: 1.1;
  text-transform: uppercase;

  color: $primary-text-color;
  min-width: 73px;
  padding: 5px 0;
  margin-right: auto;

  @media screen and (min-width: $desktop-up) {
    font-size: 20px;
    line-height: 1.1;

    padding: 20px 0;
    margin-right: 60px;
  }
}

.massage{
  font-size: 6px;
  line-height: 1.1;
  margin-left: 17px;

  @media screen and (min-width: $desktop-up) {
    font-weight: 700;
    font-size: 18px;
  }


}

.loading{
  font-size: 12px
}

.timer{
  font-family: 'Myriad-Pro';
  font-weight: 600;
  font-size: 22px;
  line-height: 1.1;
  text-transform: uppercase;
  margin-left: 10px;

  color: $primary-counter-color;

   @media screen and (min-width: $desktop-up) {
    font-size: 65px;
    line-height: 1.2;
    margin-left: 7px;
  }
}
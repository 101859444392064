@import 'style/shared';

.container {
  margin-left: auto;
  margin-right: auto;
  padding-left: 16px;
  padding-right: 16px;
  width: 100%;
  

  @media screen and (min-width: $desktop-up) {
    padding-left: 112px;
    padding-right: 112px;
  }

}
@import 'style/shared';

.wrap{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  @media screen and (min-width: $desktop-up) {
    min-width: 320px;
  }
}

.tittle {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;

  font-size: 25px;
  line-height: 120%;
  font-weight: 400;

  margin: 0 auto 28px auto;

  text-align: center;
  text-transform: uppercase;
  color: $third-tittle-color;

  span {
    margin: 0 10px;
  }

  &::before,
  &::after {
    display: block;
    content: ' ';
    background-image: url('../../assets/images/petal.svg');
    width: 29px;
    height: 6px;
    background-size: cover;
    background-position: center;
    margin-top: 8px;
  }

  &::before {
    transform: rotate(180deg);
  }

  @media screen and (min-width: $desktop-up) {
    width: 100%;
    font-size: 36px;
    line-height: 1.1;
    margin: 0 auto 80px auto;

    &::before,
    &::after {
      margin-top: 12px;
    }
    span {
      margin: 0 18px;
    }

  }


}

.authForm{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 100%;

  @media screen and (min-width: $desktop-up) {
    max-width: 320px;
  }

  //label+label{
  //  margin-top: 10px;
  //}
  label {
    margin-bottom: 12px;
    cursor: pointer;
  }

  span{
    font-family: 'Myriad-Pro';
    font-size: 17px;
    line-height: 1.1;

    color: $auth-text-color;

    margin: 0 0 8px 0;
  }
  .or {
    font-family: 'Myriad-Pro';
    color: $auth-text-color;
    font-size: 12px;
    margin: -5px 0 5px 0;
    text-transform: uppercase;
    position: relative;
    &.mr{
      margin: 6px 0 -6px 0;
    }
    &:after, &:before{
      content: '';
      background-color: rgba(197, 155, 90, .4);
      width: 36px;
      height: 0.08rem;
      position: absolute;
      top: 40%;
    }
    &:after {
      left: calc(100% + 4px);
    }
    &:before {
      right: calc(100% + 4px);
    }
  }
  //div:last-child{
  //  margin-top: 7px;
  //}
}
.ResetAuthType {
  padding: 0;
  border: none;
  background: transparent;
  color: #5F5F5F;
  font-size: 14px;
  text-transform: initial;
  font-family: 'Myriad-Pro', sans-serif;
  text-align: left;
  width: 100%;
  margin-top: 20px;
}


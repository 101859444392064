@import 'style/shared';

.wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.text {
  font-size: 16px;
  line-height: 120%;
  text-align: center;
  color: #C59B5A;

  @media screen and (min-width: $desktop-up) {
    font-size: 36px;
    line-height: 1.1;
  }

}

.card {
  margin-top: 15px;
  width: 92px;


  @media screen and (min-width: $desktop-up) {
    width: 216px;
  }

}

.btn{
  margin-top: 10px;
  padding: 10px 50px;

  font-size: 16px;
  line-height: 120%;

  border: none;
  border-radius: 10px;
  text-transform: uppercase;
  

  background-color: #C59B5A;
  color: #fff;


  @media screen and (min-width: $desktop-up) {
    font-size: 23px;
    line-height: 1.1;

    padding: 15px 50px;

    margin-top: 15px;
  }

}
.wrapShare{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  margin-top: 10px;
}

.slogan{

  font-size: 12px;
  line-height: 1.1;
  margin-right: 10px;
  color: #C59B5A;

  @media screen and (min-width: $desktop-up) {
    font-size: 20px;
  }
 
}


@import 'style/shared';

.wrap{
  display: flex;
  border: 1px solid #FFFFFF;
  border-radius: 30px;
  max-width: 50px;
  overflow: hidden;

  @media screen and (min-width: $desktop-up) {
    position: absolute;
    top: 20px;
    right: 20px;

    max-width: 110px;
  }
  @media screen and (min-width: $desktop-up) and (max-width: 992px  ) {
    border: 2px solid #FFFFFF;
  }

}

.disable,
.active{
  font-family: inherit;
  font-weight: 400;
  font-size: 8px;
  line-height: 1;
  text-align: center;
  text-transform: uppercase;
  padding: 8px 8px 4px 8px;
  border: none;


  @media screen and (min-width: $desktop-up) {
    font-size: 20px;
    line-height: 1.1;
    padding: 13px 16px 8px 16px;
  }


}

.disable{
  color: $fourth-text-color ;
  background-color: transparent;
}

.active{
  color:$primary-accent-color;
  background-color: $second-background;
}

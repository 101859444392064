@import 'style/shared';

.wrap{
  position: relative;
  width: 100%;
}

.googleBtn{
  font-family: 'Myriad-Pro';

  font-size: 13px;
  line-height: 1.1;
  padding: 15px 42px 15px 25px;
  background: $google-background;
  color:$primary-text-color ;
  border: none;
  margin: 0 auto;
  @media screen and (min-width: $desktop-up) {
    padding: 15px 42px 11px 25px;
    font-size: 16px;
  }
  width: 100%;
  &:disabled {
    opacity: .4;
  }
}

.FcCheckmark{
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(-50%, -50%);
  background-color: white;
  border: 1px solid black;
}

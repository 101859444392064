@import 'style/shared';
.question{
  margin-top: 20px;
}
.box_btn{
  display: flex;
  justify-content: space-between;

  margin-top: 40px;
}
.text{
  font-size: 13px;
  
  @media screen and (min-width: 400px) {
    font-size: 22px;
  }
}

.btn_reject,
.btn_accept{
  font-size: 13px;
  border: none;
  padding: 7px 5px 3px 5px;
  color:$primary-text-color ;

  @media screen and (min-width: 400px) {
    font-size: 20px;
    padding: 14px 20px 6px 20px;
  }

}

.btn_reject{
  
  background-color: #C59B5A;

}
.btn_accept{
  background-color: red;
  margin-left: 5px;
}
@import 'style/shared';

body {
  background-image: url('../../assets/images/lev.png');
  background-size: 80%;
  background-position: center 0%;
  background-attachment: fixed;
  background-repeat: no-repeat;
}

main {

  section+section {
    position: relative;
    padding-top: 65px;
    z-index: 200;

    @media screen and (min-width: $desktop-up) {
      padding-top: 100px;
    }
  }
}

.win {
  @media screen and (min-width: $desktop-up) {
    .crop {
      top: -50px;
    }
    &::after {
      bottom: 110px !important;
    }
  }
}

.homeMain {
  padding: 0 0 40px 0;

  @media screen and (min-width: $desktop-up) {
    padding: 0 0 70px 0;

    &::before {
      display: block;
      position: absolute;
      top: 147px;
      right: 0;
      width: 269px;
      height: 628px;
      content: ' ';
      background-image: url('../../assets/images/flag_tree.png');
      background-size: cover;
      background-position: center;
      z-index: 150;
    }
  }
}

//===============Hero section===============//
.heroSection {
  position: relative;
  background-image: url('../../assets/images/Lvivske-Football-Board.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding-top: 110px;
  padding-bottom: 30px;
  overflow: hidden;
  clip-path: polygon(100% -100%, 100% 97%, 50% 100%, 0% 97%, 0% 0%, 0% 0%);

  &::after {
    display: block;
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 230px;
    height: 15px;
    transform: rotate(180deg) translate(50%, 50%);
    content: ' ';
    background-image: url('../../assets/images/footer_check_mob.png');
    background-size: cover;
    background-position: center;

    @media screen and (min-width: $desktop-up) {
      background-image: url('../../assets/images/footer_check.png');
      width: 1785px;
      height: 122px;
    }

  }


  @media screen and (min-width: $desktop-up) {
    clip-path: polygon(100% -100%, 100% 92%, 50% 100%, 0% 92%, 0% 0%, 0% 0%);
    //padding-top: 150px;
    padding-top: 317px;
    padding-bottom: 151px;

    &::before {
      display: block;
      position: absolute;
      top: 400px;
      left: 0;
      width: 418px;
      height: 527px;
      content: ' ';
      background-image: url('../../assets/images/flag_one.png');
      background-size: cover;
      background-position: center;
      z-index: 150;
    }
  }
}

.wrap {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (min-width: $desktop-up) {
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    margin-left: 210px;
  }

}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;

  nav {
    position: relative;
    z-index: 550;
  }
}

.mainSquad {
  @media screen and (min-width: $desktop-up) {
    &::before {
      display: block;
      position: absolute;
      top: 800px;
      left: 0;
      width: 185px;
      height: 472px;
      content: ' ';
      background-image: url('../../assets/images/flag_four.png');
      background-size: cover;
      background-position: center;
    }

    &::after {
      display: block;
      position: absolute;
      top: 383px;
      right: 0;
      width: 118px;
      height: 272px;
      content: ' ';
      background-image: url('../../assets/images/flag_two.png');
      background-size: cover;
      background-position: center;
      z-index: 150;
    }
  }
}

.mainSquadWrap {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.spareSquad {
  @media screen and (min-width: $desktop-up) {
    &::after {
      display: block;
      position: absolute;
      bottom: -135px;
      right: 0;
      width: 448px;
      height: 195px;
      content: ' ';
      background-image: url('../../assets/images/flag_six.png');
      background-size: cover;
      background-position: center;
      z-index: 150;
    }
  }
}

.heroTitle,
.winnerTitle {
  display: block;
  font-weight: inherit;
  margin-top: 5px;

  @media screen and (min-width: $desktop-up) {
    margin-top: 56px;
    min-width: 100%;
  }

  span {
    display: block;
    text-align: center;
  }

  .levelOne {
    font-size: 14px;
    line-height: 1.1;
    text-transform: uppercase;

    color: $primary-tittle-color;

    @media screen and (min-width: $desktop-up) {
      font-size: 52px;
      margin-bottom: 25px;
    }

  }

  .levelTwo {
    font-size: 21px;
    line-height: 1.1;

    margin-top: 6px;

    text-transform: uppercase;
    color: $primary-tittle-color;

    @media screen and (min-width: $desktop-up) {
      font-size: 82px;
      line-height: 1.1;
      margin-bottom: 28px;
    }

  }

  .levelTree {
    font-size: 40px;
    line-height: 1.1;

    text-transform: uppercase;
    margin-top: 3px;

    color: $second-tittle-color;

    @media screen and (min-width: $desktop-up) {
      font-size: 152px;
      line-height: 1.1;
    }

  }

  .levelTreeEN {
    font-size: 29px;
    line-height: 1.5;

    text-transform: uppercase;
    margin-top: 3px;

    color: $second-tittle-color;


    @media screen and (min-width: $desktop-up) {
      font-size: 108px;
      line-height: 1.1;
    }

  }
}

.Kubok {
  position: absolute;
  bottom: 59px;
  left: 45px;
  max-width: initial;
  height: 164px;

  @media screen and (min-width: $desktop-up) {
    position: relative;
    top: -70px;
    left: 82px;
    height: 682px;
    width: auto;
  }
}

.winnerCover {
  display: block;
  width: auto;
  height: 222px;
  max-width: initial;

  @media screen and (min-width: $desktop-up) {
    border-radius: 30px;
    height: 633px;
  }
}

.winnerName {
  margin-top: 20px;
  color: $third-text-color;

  @media screen and (min-width: $desktop-up) {
    margin: 20px 0;
    font-size: 90px;
    line-height: 1.1;
  }
}

.crop {
  position: relative;
  top: 19px;
  clip-path: polygon(50% -100%, 100% 95%, 53% 100%, 0% 95%);

  @media screen and (min-width: $desktop-up) {
    clip-path: polygon(100% -100%, 100% 87%, 0% 100%, 0% 100%, 0% 0%, 0% 0%);
    top: 64px;
    left: -70px;
    margin-top: 100px;
  }
}

.winnerTitle {

  @media screen and (min-width: $desktop-up) {
    //margin-top: 280px;
    margin-top: 150px;
    min-width: 570px;
  }
}

.tittle{
  span{
    width: 180px;
  }
  .sm {
    width: 146px;
    @media screen and (min-width: $desktop-up) {
      width: fit-content;
    }
  }
}

.tittle,
.secondTittle {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;

  font-size: 16px;
  line-height: 120%;
  margin: 0 auto 53px auto;

  text-align: center;
  text-transform: uppercase;
  color: $third-tittle-color;


  &::before,
  &::after {
    display: block;
    content: ' ';
    background-image: url('../../assets/images/petal.svg');
    width: 16px;
    height: 3px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    margin-top: 24px;
  }

  &::before {
    transform: rotate(180deg);
  }

  @media screen and (min-width: $desktop-up) {
    font-size: 50px;
    line-height: 1.1;
    margin: 0 40px 60px 40px;

    span {
      width: fit-content;
      margin: 0 40px;
    }

    &::before,
    &::after {
      width: 41px;
      height: 6px;
      margin-top: 12px;
    }
  }
}


.secondTittle {
  span {
    margin: 0 10px;
  }

  &::before,
  &::after {
    margin-top: 5px;
  }



  @media screen and (min-width: $desktop-up) {
    min-height: 30px;

    span {
      margin: 0 40px;
    }

    &::before,
    &::after {
      margin-top: 12px;
    }
  }

}

//=============Player section ============//

.playerList {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: -28px -16px;

  @media screen and (min-width: $desktop-up) {
    margin: -50px -38px;
  }
}

.playerItem {
  margin: 28px 16px;
  width: 93px;

  @media screen and (min-width: $desktop-up) {
    width: 210px;
    margin: 50px 38px;
  }
}

nav {
  display: inline-block;
  background-image: url('../../assets/images/hero_bord_mob.png');
  background-size: cover;
  background-position: center;
  padding: 15px 24px;
  margin: 154px auto 0 auto;


  @media screen and (min-width: $desktop-up) {
    margin: 0;
    background-image: url('../../assets/images/hero_bord.png');
    padding: 41px 103px 41px 97px;
  }
}

// News
.wrapNews {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;

  li+li {
    margin-top: 20px;
  }

  @media screen and (min-width: $desktop-up) {
    justify-content: space-between;

    li+li {
      margin-top: 0;
    }

    li:last-child {
      margin-top: 20px;
    }
  }

}

// Calendar
.secondTittle.tittleDark{
  color: $primary-background;
  margin: 0 auto 26px auto;
  @media screen and (min-width: $desktop-up) {
    margin: 0 auto 53px auto;
  }

  &::before,
  &::after {
    background-image: url('../../assets/images/petalDark.svg');
  }
}
.bgOrange{
  background: $orange-background;
  margin-top: 48px;
  padding-bottom: 36px;
  padding-top: 36px;
  max-width: 100%;
  overflow: hidden;
  @media screen and (min-width: $desktop-up) {
    margin-top: 86px;
    padding-bottom: 80px;
    padding-top: 68px;
  }
}

@import 'style/shared';

.paginateWrap{
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (min-width: $desktop-up) {
    margin-top: 5px;

  }
}
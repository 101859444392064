@import 'style/shared';
@import 'style/fonts/fonts.scss';

@import 'style/global/global.scss';
@import 'style/global/reset.scss';


@import 'style/components/editor.scss';


html {
  scroll-behavior: smooth;
  transform-origin: left top;
  scrollbar-width: thin;

  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-thumb {
    background: gray;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }
}

body {
  font-family: Lvivske-Basic, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 20px;
  line-height: 1.1;
  font-weight: 400;
  background-color: $primary-background;
  margin: 0;
  padding: 0;
  overflow-y: overlay;
  overflow-x: hidden;

}

.scrollStop {
  overflow: hidden;
}




@import 'style/shared';

.main {
  background-color: $second-background;
  section {
  padding: 50px 0;

  @media screen and (min-width: $desktop-up) {
    padding: 150px 0;
    margin: 0 150px;
  }
}
}



.tittle,
.secondTittle {
  font-family: Myriad-Pro;
  font-size: 18px;
  line-height: 1;
  margin-bottom: 10px;
  color: $third-tittle-color;
  text-align: center;

  @media screen and (min-width: $desktop-up) {
    font-size: 30px;
    text-align: start;
  }
}

.tittle {
  font-size: 20px;
  text-align: center;

  @media screen and (min-width: $desktop-up) {
    font-size: 40px;
  }
}

.policy_item {
  margin-top: 10px;
}

.create_data {
  display: block;
  margin-top: 20px;
}

.text,
.create_data {
  font-family: Myriad-Pro;
  font-size: 10px;
  line-height: 1;
  @media screen and (min-width: $desktop-up) {
    font-size: 20px;
 
  }
}

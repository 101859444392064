@import 'style/shared';
.favoritesList{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  min-height: 31em;

  margin: -15px -16px;
  @media screen and (min-width: $desktop-up) {
    margin: -50px -38px;
    min-height: 22em;
  }
}

.favoritesItem{
  display: flex;
  flex-direction: column;
  width: 92px;
  margin: 15px 16px;

  @media screen and (min-width: $desktop-up) {
    width: 210px;
    margin: 50px 38px;
  }
}

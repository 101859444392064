@import 'style/shared';

.line {
  display: flex;
  width: 192px;
  
  @media screen and (min-width: $desktop-up) {
    width: 304px;;
  }
}

.count {
  font-size: 24px;
  line-height: 1.1;
  color: $fourth-text-color;

  @media screen and (min-width: $desktop-up) {
    font-size: 44px;
    line-height: 1.1;
  }
}

.text {
  font-family: Myriad-Pro;
  font-size: 12px;
  line-height: 1;
  color: $fourth-text-color;
  width: 160px;
  margin-left: 7px;

  @media screen and (min-width: $desktop-up) {
    font-size: 16px;
    line-height: 1.1;
    width: 100%;
    margin-left: 14px;
  }
}

@import 'style/shared';

.Box{
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 12px;
  width: 100%;

  @media screen and (max-width: 768px) {
    margin-top: 0;
  }
}

.CoverLinkFooter{
  width: 33px;
  height: 34px;

  @media screen and (min-width: $desktop-up) {
    width: 56px;
    height: 88px;
  }
}

.CoverLinkMain{
  width: 24px;
  height: 38px;

  @media screen and (min-width: $desktop-up) {
    width: 67px;
    height: 108px;
  }
}

.CoverBtn{
  width: 22px;

  @media screen and (min-width: $desktop-up) {
    width: 53px;
  }
}
.CoverAuthBtn{
  width: 100%;
  height: 100%;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

  @media screen and (min-width: $desktop-up) {
    width: 53px;
    height: 84px;
  }
  @media screen and (max-width: 768px) {
    width: 100%;
    max-width: 39px;
    height: auto;
  }
}

.btn{
  display: block;
  text-align: center;
  background-color: transparent;
  padding: 6px 5px ;
  margin-left: 10px;
  height: 21px;
  font-size: 8px;
  line-height: 1.1;
  text-transform: uppercase;
  border: 1px solid #16894B;
  color: #16894B;
  @media screen and (min-width: $desktop-up) and (max-width: 992px ) {
    border: 2px solid #16894B;
  }
  &.active{
    color: #C59B5A;
    border: 1px solid #C59B5A;
    @media screen and (min-width: $desktop-up) and (max-width: 992px ) {
      border: 2px solid #C59B5A;
    }
  }
  &:hover{
    color: #C59B5A;
    border: 1px solid #C59B5A;
    @media screen and (min-width: $desktop-up) and (max-width: 992px ) {
      border: 2px solid #C59B5A;
    }
  }
  &:disabled{
    pointer-events: none;
    cursor: default;
    border: 1px solid rgb(168, 164, 164) !important;
    color: rgb(168, 164, 164) !important;
    @media screen and (min-width: $desktop-up) and (max-width: 992px ) {
      border: 2px solid rgb(168, 164, 164) !important;
    }
  }


  @media screen and (min-width: $desktop-up) {
    font-size: 20px;
    line-height: 1.1;
    padding: 18px 10px 16px 10px;

    height: 100%;
  }

}

.AuthBtn{
  display: block;
  text-align: center;
  background-color: transparent;
  padding: 12px 7px 6px 7px;
  margin-left: 10px;
  width: 100%;
  font-size: 13px;
  line-height: 1.1;
  text-align: center;
  text-transform: uppercase;
  color: #C59B5A;
  border: 1px solid #C59B5A;
  @media screen and (min-width: $desktop-up) and (max-width: 992px ) {
    border: 2px solid #C59B5A;
  }
  @media screen and (min-width: $desktop-up) {
    font-size: 20px;
    line-height: 1.1;
    padding: 17px 12px 9px 18px;

  }
   &:disabled{
    cursor: default;
    border: 1px solid rgb(168, 164, 164) !important;
    color: rgb(168, 164, 164) !important;
  }
}


.linkFooter,
.linkHero{
  padding: 8px 5px 4px 5px;
  border: 1px solid $primary-border-color;
  text-align: center;
  text-transform: uppercase;
  color: $fourth-text-color;

  @media screen and (min-width: $desktop-up) {
    padding: 23px 13px 14px 13px;
  }
}

.linkFooter{
  font-size: 14px;
  line-height: 1.1;

  margin-left: 15px;

  @media screen and (min-width: $desktop-up) {
    font-size: 24px;
    line-height: 1.1;
  }
}

.linkHero{
  font-size: 14px;
  line-height: 1.1;

  margin-left: 7px;

  @media screen and (min-width: $desktop-up) {
    font-size: 24px;
    line-height: 1.1;
    margin-left: 20px;
  }
}
